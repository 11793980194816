import React, { Component } from "react";
import { Link } from "react-router-dom";
import facebook from "assets/images/icons/facebook-fill.svg";
import instagram from "assets/images/icons/instagram-fill.svg";
import linkedin from "assets/images/icons/linkedin-fill.svg";
import bggirl1 from "assets/images/bgs/bggirl2.png";


class Footer extends Component {
    state = {};

    render() {
        return (
            <>
                <div className="bg-black pt-14">
                    <div className="container text-white">

                        <div className="px-4 sm:px-0 text-center sm:text-left lg:flex">
                          
                            <div className="lg:w-2/5 hidden lg:block">
                                <img src={bggirl1} alt="bgGirl1" className="object-cover p-8 xl:mt-4" />
                            </div>
                                
                            

                            <div className="bg-juvi-yellow h-1 my-7 lg:hidden opacity-20"></div>

                            <div className="lg:flex-grow grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-14">

                                <div>
                                    <div>
                                        <Link to="" className="font-medium text-xl hover:text-juvi-yellow">
                                            People
                                        </Link>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Community
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Welfare
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Scholarships
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                              
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    <div>
                                        <Link to="" className="font-medium text-xl hover:text-juvi-yellow">
                                            Our Partners
                                        </Link>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                               Achims Signature
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                            PlarnaCare
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                               
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                               
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                {/** <div>
                                    <div>
                                        <Link to="" className="font-medium text-xl hover:text-juvi-yellow">
                                            Features
                                        </Link>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                smart contract
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Wallet System
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Product Listings
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Vendors
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="" className="font-light hover:text-juvi-yellow">
                                                Order Processing
                                            </Link>
                                        </div>
                                    </div>
        </div> */}
                                
                                <div>
                                    <div>
                                         <Link to="" className="font-medium text-xl hover:text-juvi-yellow">
                                            Contact Us
                                        </Link>
                                    </div>
                                    <div className="mt-4">
                                        <div>
                                            {/** <Link to="" className="block font-light hover:text-juvi-yellow">
                                                Rumola, Rivers State, Nigeria.
                                            </Link>*/}
    </div> 
                                        
                                            <div className="font-light">
                                                Call Us: <p> <a href="tel:+234 907 096 8838" className="hover:text-juvi-yellow">+234 907 096 8838</a></p>
                                            </div>
                                            <div className="font-light">
                                                Email Us: <a href="mailto:Tadarofoundation@gmail.com" className="hover:text-juvi-yellow">Tadarofoundation@gmail.com</a>
                                            </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                            <div className="sm:flex pb-4 text-center sm:text-left pt-16 sm:pt-24">
                                <div className="w-full sm:w-1/2">
                                <div className="flex">
                                    <div className="mx-auto sm:mx-0 flex flex-wrap space-x-10">
                                        <Link to="" className="pb-1 border-b border-transparent hover:border-white">
                                            We are Social
                                        </Link>
                                       {/** <Link to="" className="pb-1 border-b border-transparent hover:border-white">
                                            Products
                                        </Link>  */}
                                        <Link to="" className="pb-1 border-b border-transparent hover:border-white">
                                            
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex-wrap sm:w-1/2 mt-4 sm:mt-0">
                                <div className="flex sm:justify-end">
                                    <div className="mx-auto sm:mx-0 flex space-x-4">
                                        {/** <Link to="" className="w-6 h-6 block pb-1 border-b border-transparent hover:border-white">
                                            <img src={facebook} alt="facebook" className="object-contain" />
                                    </Link> */}
                                        <Link to="https://instagram.com/Tadaro_foundation" className="w-6 h-6 block pb-1 border-b border-transparent hover:border-white">
                                            <img src={instagram} alt="facebook" className="object-contain" />
                                        </Link>
                                        {/** <Link to="" className="w-6 h-6 block pb-1 border-b border-transparent hover:border-white">
                                            <img src={linkedin} alt="facebook" className="object-contain" />
                                </Link> */}
                                    </div>
                                </div>
                            </div>  
                        </div>   
                   

                    </div>
                </div>
            </>
        );
    }
}

export default Footer;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Header from "components/common/header.jsx";
import Footer from "components/common/footer.jsx";
import bggirl from "assets/images/bgs/bggirl.png";
import Ellipse from "assets/images/bgs/Ellipse717.svg";
import chartData from "assets/images/bgs/chart-data.svg";
import trophy from "assets/images/icons/trophy.svg";
import circleDollar from "assets/images/icons/circle-dollar.svg";
import circleShopping from "assets/images/icons/circle-shopping.svg";
import zigzag from "assets/images/icons/zigzag.svg";
import apple from "assets/images/icons/apple.svg";
import appleWhite from "assets/images/icons/apple-white.svg";
import playStore from "assets/images/icons/play-store.svg";
import arrow from "assets/images/icons/arrow.svg";
import checkBadge from "assets/images/icons/check-badge.svg";
import salesHot from "assets/images/icons/sales-hot.svg";



class Home extends Component {
    
    state = {};

    hteClick = (target, e) => {
        // console.log("target",target);
        // console.log("e",e);
        
        $(".hte-div").addClass("hidden");
        $("#"+target).removeClass("hidden");

        $(".hte-indicator").addClass("invisible");
        $("."+target).children().eq(0).removeClass("invisible");
    };

    render() {

        return (
            <>
                <Header />

                <div>

                    <div className="bg-white">
                        <div className="container flex">

                            <div className="w-full pb-10 md:pb-20 lg:w-2/4">

                                <div className="mt-14 lg:mt-20 font-shadows text-2xl sm:text-3xl md:text-4xl xl:text-4xl text-black">
                                The light that shines. John 1:5.
                                </div>
                                <br /> 
                                <div className="text-5xl md:text-6xl xl:text-8xl mt-2 font-semibold text-juvi-red">
                                Students Scholarship.
                                    <br /> 
                                    
                                    Widows Welfare..
                                </div>

                                <div className="my-4 xl:my-6">
                                    <img src={arrow} alt="Arrow" className="object-contain h-3 xl:h-10" />
                                </div>

                                <div className="text-black xl:text-lg font-light leading-7">
                                We support students who can't afford basic education by providing
                                    <br className="hidden lg:block" />
                                    <span className="inline lg:hidden">&nbsp;</span>
                                    scholarship and grant schemes, and supporting young widows
                                    <br className="hidden lg:block" />
                                    <span className="inline lg:hidden">&nbsp;</span>
                                    by providing emotional and support systems for them to nounce back after their loss.
                                </div>

                                <div className="flex flex-wrap mt-5 xl:mt-10">

                                   <Link to="" className="mb-4 mr-4 md:mr-8 lg:mr-4 xl:mr-8 px-6 lg:px-4 xl:px-6 h-12 bg-juvi-red whitespace-nowrap rounded-full flex animate hover:scale-110">
                                        <div className="flex my-auto">
                                        <div className="m-auto flex space-x-2 md:space-x-4">
                                        <a href="https://paystack.com/pay/tadarofoundation" className="btn btn-lg animate btn-black text-white">
                                          Donate Here 
                                        </a> 
                                        </div>
                                            
                                        </div>
        </Link>

                                
                                   

                                </div>  

                            </div>

                            <div className="lg:w-2/4 hidden lg:block">
                                <img src={bggirl} alt="bgGirl" className="object-contain p-8 mt-14 xl:mt-14" />
                            </div>

                        </div>
                    </div>

                    <div className="pt-10 bg-juvi-red mb-9">
                        <div className="container">

                            <div className="text-center sm:text-left text-2xl md:text-3xl xl:text-4xl pb-4 font-shadows text-white">
                                Who we are
                            </div>
                            <div className="text-center sm:text-left sm:text-xl md:text-3xl xl:text-3xl font-light mt-3 sm:mt-4 md:mt-4 md:space-y-3 text-white">
                                <div>
                                Tadaro Foundation is an NGO in Rivers State, Nigeria. 
                                Involved in the provision of educational scholarships to  
                                students who are unable to pay their tuition fees due to financial constraint.
                                The foundation is also involved in granting social welfare to young widows.
                                </div>
                            
                                <div>
                                 
                                
                                </div>
                            </div>

                            <div className="text-center sm:text-left space-y-5 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-10 lg:gap-x-14 xl:gap-x-24 mt-6 md:mt-12">

                                <div className="max-w-xs mx-auto sm:mx-0">
                                    <div className="w-20 h-20 mx-auto sm:mx-0 bg-white shadow-xl rounded-full flex">
                                        <img src={checkBadge} alt="Verified Vendors" className="object-contain m-auto w-8 h-8" />
                                    </div>
                                    <div className="font-light text-lg sm:text-xl md:text-3xl mt-3 sm:mt-6 text-white">
                                    Vision
                                    </div>
                                    <div className="text-white xl:text-lg font-light leading-8">
                                    Our vision is to develop well rounded children by 
                                    helping them to thrive academically and
                                     sponsoring widows to achieve their purpose.
                                    </div>
                                </div>

                                <div className="max-w-xs mx-auto sm:mx-0 mb-8 pb-10">
                                    <div className="w-20 h-20 mx-auto sm:mx-0 bg-white shadow-xl rounded-full flex">
                                        <img src={salesHot} alt="Verified Vendors" className="object-contain m-auto w-8 h-8" />
                                    </div>
                                    <div className="font-light text-lg sm:text-xl md:text-3xl mt-3 sm:mt-6 text-white">
                                    Mission
                                    </div>
                                    <div className="text-white xl:text-lg font-light leading-7 mb-4">
                                    Our mission is to contribute to society building by 
                                    making basic education accessible to those who cannot afford it and to give 
                                    young widows hope & resources to start again.
                                    </div>
                                </div>

                               {/** <div className="max-w-xs mx-auto sm:mx-0">
                                    <div className="w-24 h-24 mx-auto sm:mx-0 bg-white shadow-xl rounded-full flex">
                                        <img src={shipping} alt="Verified Vendors" className="object-contain m-auto w-10 h-10" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6">
                                    Marketplace
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal">
                                    Data generated from tracking farm produce is used to build farmers credit worthiness,
                                     access education, healthcare for themsleves and their family members
                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>


                    <div className="pt-14 md:pt-20">
                        <div className="container">

                            <div className="text-center text-2xl md:text-4xl font-semibold text-juvi-red">
                            At Tadaro Foundation
                            </div>

                            <div className="mt-12 md:flex xl:space-x-10">
                                
                                <div className="w-32 flex-shrink-0 hidden xl:block"></div>

                                <div className="w-full md:max-w-xs p-7 sm:p-10 md:mr-8 xl:mr-0 bg-white flex-shrink-0 flex rounded-xl shadow-2xl">

                                    <div className="space-y-2 md:space-y-4 relative my-auto">
                                        <div className="absolute z-0 w-1 h-full bg-gray-200 top-0 left-0.5 rounded-full"></div>

                                        <div className="flex relative z-10 group cursor-pointer hte-div-1" onClick={this.hteClick.bind(null, 'hte-div-1')}>
                                            <div className="w-2 flex-shrink-0 bg-juvi-red mr-6 rounded-full hte-indicator"></div>
                                            <div className="py-2 md:py-3 md:text-lg group-hover:text-juvi-red overflow-hidden inline-block text-xl">
                                                What we do at the Foundation 
                                            </div>
                                        </div>

                                        <div className="flex relative z-10 group cursor-pointer hte-div-2" onClick={this.hteClick.bind(null, 'hte-div-2')}>
                                            <div className="w-2 flex-shrink-0 bg-juvi-red mr-6 rounded-full hte-indicator invisible"></div>
                                            <div className="py-2 md:py-3 md:text-lg group-hover:text-juvi-red overflow-hidden inline-block text-xl">
                                           How we do it
                                            </div>
                                        </div>


                                        <div className="flex relative z-10 group cursor-pointer hte-div-3" onClick={this.hteClick.bind(null, 'hte-div-3')}>
                                            <div className="w-2 flex-shrink-0 bg-juvi-red mr-6 rounded-full hte-indicator invisible"></div>
                                            <div className="py-2 md:py-3 md:text-lg group-hover:text-juvi-red overflow-hidden inline-block text-xl">
                                                Our Focus
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="mt-5 md:mt-0 flex-grow relative z-10 p-7 sm:p-10 bg-gray-100 overflow-hidden rounded-xl shadow-md">

                                    <div id="hte-div-1" className="hte-div">
                                        <div className="text-xl font-semibold">
                                            What we do at the Foundation
                                        </div>
                                        
                                        <div className="mt-8 xl:text-lg font-light">
                                        Our scholarship scheme is aimed at reducing the level of 
                                        literacy in the society by making basic
                                         education available to those who cannot afford it.
                                         <p>
                                        Our social welfare is aimed at meeting the needs of 
                                        less privileged young widows in the society.
                                        </p>
                                        </div>
                                    </div>

                                    <div id="hte-div-2" className="hte-div hidden">
                                      
                                        <div className="text-2xl font-semibold">
                                           How we do it
                                            <br className="hidden xl:block" />
                                            <span className="inline xl:hidden">&nbsp;</span>
                                            
                                        </div>
                                        
                                        <div className="mt-8 xl:text-lg font-light">
                                        Scholarships - We guide these children through school, 
                                        offering scholarships and ensuring they get standard education. 
                                        <p>Widows - We give grants, provide emotional support, & support 
                                        ideas/businesses to make them stand again after 
                                        their loss, to establish these women effectively, they would not only be given financial grants, but they would be sensitized and given the emotional, legal education needed to help 
                                        them stand back in the society after their loss.
                                        </p>
                                        </div>
                                    </div>

                                    <div id="hte-div-3" className="hte-div hidden">
                                        <div className="text-2xl font-semibold">
                                           Our Focus
                                        </div>
                                        
                                        <div className="mt-8 xl:text-lg font-light">
                                        Our target audience for educational support are students from 
                                        financially constrained backgrounds who cannot afford basic education.
                                        
                                        <p>For widows, our targets re young widows within the age of 20-35.
                                        </p>
                                        </div>
                                    </div>

                                   {/* <div className="flex space-x-8 mt-14">
                                        <Link to="" className="px-6 h-12 bg-juvi-red whitespace-nowrap rounded-full flex animate hover:scale-110">
                                            <div className="flex my-auto">
                                                <img src={appleWhite} alt="App Store" className="object-contain h-6 mr-2" />
                                                <div className="text-left my-auto leading-3">
                                                    <div className="font-light font-tiny -mt-px text-white">
                                                        Available on
                                                    </div>
                                                    <div className="font-medium mt-px text-white">
                                                        App Store
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link to="" className="px-6 h-12 bg-juvi-red whitespace-nowrap rounded-full flex animate hover:scale-110">
                                            <div className="flex my-auto">
                                                <img src={playStore} alt="Play Store" className="object-contain h-6 mr-2" />
                                                <div className="text-left my-auto leading-3">
                                                    <div className="font-light font-tiny -mt-px text-white">
                                                        Available on
                                                    </div>
                                                    <div className="font-medium mt-px text-white">
                                                        Play Store
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
        </div> */}
                                </div>

                                <div className="xl:w-32 flex-shrink-0 relative z-0 hidden sm:block">
                                    <div className="absolute z-0 w-20 h-20 -bottom-10 -right-10 md:w-36 md:h-36 md:-bottom-16 md:-left-32">
                                        <img src={Ellipse} alt="Ellipse" className="object-contain" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                     {/** <div className="mt-10 relative">

                        <div className="flex absolute z-0 w-full marketplace-gist-bg">
                            <div className="w-full lg:w-4/5 lg:flex-grow bg-black"></div>
                            <div className="w-1/5 flex-shrink-0 hidden lg:block">
                                <img src={zigzag} alt="zigzag" className="w-48 mt-36 ml-20 object-contain" />
                            </div>
                        </div>

                        <div className="container relative z-10 lg:flex">
                            <div className="lg:w-4/5 flex-grow">
                                <div className="xl:flex xl:space-x-10 pt-20">
                                    <div className="xl:w-1/2 text-4xl xl:text-6xl font-semibold text-white">
                                        Our marketplace gists
                                        <img src={arrowRed} alt="Arrow Red" className="xl:-mt-9 xl:ml-48 float-right xl:float-none object-contain" />
                                    </div>
                                    <div className="xl:w-1/2 text-white text-lg xl:text-xl font-light mt-4 xl:leading-10">
                                        Get the recent approach and market experience sellers are using to attract customers and more ways to earn more  
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/5 flex-shrink-0 hidden lg:block"></div>
                        </div>

                        <div className="container relative z-10 mt-10 xl:mt-16 md:grid md:grid-cols-3 md:gap-4 lg:gap-8 xl:gap-14 space-y-8 md:space-y-0">

                            <Link to="" className="bg-white block max-w-sm mx-auto rounded-lg p-2 md:p-1 lg:p-2 shadow-xl animate hover:scale-110">
                                <div className="w-full h-48 xl:h-64 bg-black rounded-lg overflow-hidden">
                                    <img src={rectangle1} alt="rectangle 1" className="object-cover w-full h-full" />
                                </div>
                                <div className="p-3 h-36 lg:h-40 xl:h-48">
                                    <div className="mt-1 lg:mt-2 xl:mt-5 text-juvi-red uppercase text-xs lg:text-sm font-semibold tracking-widest">
                                        MARKETING
                                    </div>
                                    <div className="font-semibold text-base lg:text-lg xl:text-xl mt-2 xl:mt-4 ellipsis-2-lines">
                                        Habitasse cras imperdieffffggt nislcras imperdiet nisl.
                                    </div>
                                    <div className="flex mt-4 xl:mt-6 space-x-2">
                                        <div className="w-1/2 text-sm ellipsis">
                                            Anna Nicole
                                        </div>
                                        <div className="w-1/2 text-sm text-right justify-end text-gray-400 ellipsis">
                                            Feb 20,2021
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            
                            <Link to="" className="bg-white block max-w-sm mx-auto rounded-lg p-2 md:p-1 lg:p-2 shadow-xl animate hover:scale-110">
                                <div className="w-full h-48 xl:h-64 bg-black rounded-lg overflow-hidden">
                                    <img src={rectangle2} alt="rectangle 2" className="object-cover w-full h-full" />
                                </div>
                                <div className="p-3 h-36 lg:h-40 xl:h-48">
                                    <div className="mt-1 lg:mt-2 xl:mt-5 text-juvi-red uppercase text-xs lg:text-sm font-semibold tracking-widest">
                                        MARKETING
                                    </div>
                                    <div className="font-semibold text-base lg:text-lg xl:text-xl mt-2 xl:mt-4 ellipsis-2-lines">
                                        Habitasse cras imperdieffffggt nislcras imperdiet nisl.
                                    </div>
                                    <div className="flex mt-4 xl:mt-6 space-x-2">
                                        <div className="w-1/2 text-sm ellipsis">
                                            Anna Nicole
                                        </div>
                                        <div className="w-1/2 text-sm text-right justify-end text-gray-400 ellipsis">
                                            Feb 20,2021
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            
                            <Link to="" className="bg-white block max-w-sm mx-auto rounded-lg p-2 md:p-1 lg:p-2 shadow-xl animate hover:scale-110">
                                <div className="w-full h-48 xl:h-64 bg-black rounded-lg overflow-hidden">
                                    <img src={rectangle3} alt="rectangle 3" className="object-cover w-full h-full" />
                                </div>
                                <div className="p-3 h-36 lg:h-40 xl:h-48">
                                    <div className="mt-1 lg:mt-2 xl:mt-5 text-juvi-red uppercase text-xs lg:text-sm font-semibold tracking-widest">
                                        MARKETING
                                    </div>
                                    <div className="font-semibold text-base lg:text-lg xl:text-xl mt-2 xl:mt-4 ellipsis-2-lines">
                                        Habitasse cras imperdieffffggt nislcras imperdiet nisl.
                                    </div>
                                    <div className="flex mt-4 xl:mt-6 space-x-2">
                                        <div className="w-1/2 text-sm ellipsis">
                                            Anna Nicole
                                        </div>
                                        <div className="w-1/2 text-sm text-right justify-end text-gray-400 ellipsis">
                                            Feb 20,2021
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            
                        </div> 
                        
                    </div> **/}



                    <div className="pt-20">
                        <div className="container">

                            <div className="max-w-2xl text-center mx-auto">

                                <div className="text-center text-2xl md:text-3xl xl:text-4xl font-shadows text-juvi-red">
                                    Our Plan
                                </div>
                                <div className="text-center text-2xl md:text-4xl xl:text-5xl font-semibold mt-2 xl:mt-4">
                                    We plan to achieve mind blowing results in a short space of time
                                </div>
                                <div className="flex mt-4 xl:mt-10">
                                    <img src={trophy} alt="trophy" className="w-10 h-10 xl:w-16 xl:h-16 object-contain m-auto" />
                                </div>
                            </div>

                        </div>


                        <div className="bg-map mt-8 pb-36">
                            <div className="container">

                                <div className="grid grid-cols-2 sm:grid-cols-none sm:flex sm:justify-around sm:items-end relative z-10 gap-y-6 sm:gap-y-0">
                                    <div className="w-40 h-40 sm:w-36 sm:h-32 mx-auto sm:mx-0 md:w-44 md:h-36 lg:w-52 lg:h-44 p-4 xl:w-72 xl:h-60 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl md:text-4xl xl:text-5xl font-semibold text-juvi-red">
                                                100+
                                            </div>
                                            <div className="text-gray-500 text-sm md:text-base xl:text-lg leading-4 md:leading-6 mt-1 md:mt-2">
                                                Students Supported
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className="w-40 h-40 mx-auto sm:mx-0 md:w-60 md:h-48 lg:w-72 lg:h-60 p-4 xl:w-80 xl:h-72 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold text-juvi-red">
                                                50
                                            </div>
                                            <div className="text-gray-500 text-sm sm:text-base xl:text-lg sm:leading-6 mt-1 sm:mt-2">
                                                Young Widows
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="w-40 h-40 sm:w-36 sm:h-32 mx-auto sm:mx-0 md:w-44 md:h-36 lg:w-52 lg:h-44 p-4 xl:w-72 xl:h-60 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl md:text-4xl xl:text-5xl font-semibold text-juvi-red">
                                                20
                                            </div>
                                            <div className="text-gray-500 text-sm md:text-base xl:text-lg leading-4 md:leading-6 mt-1 md:mt-2">
                                            Schools within Nigeria
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 sm:grid-cols-none sm:flex mt-6 sm:-mt-8 lg:-mt-14 xl:-mt-20 sm:mx-16 md:mx-24 xl:mx-48 sm:justify-around sm:items-end gap-y-6 sm:gap-y-0">
                                    <div className="w-40 h-40 mx-auto sm:mx-0 md:w-60 md:h-48 lg:w-72 lg:h-60 p-4 xl:w-80 xl:h-72 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold text-juvi-red">
                                                10
                                            </div>
                                            <div className="text-gray-500 text-sm sm:text-base xl:text-lg sm:leading-6 mt-1 sm:mt-2">
                                                Partners
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                    </div>


                   <div className="-mt-28 sm:-mt-10">
                        <div className="container pb-12 md:pb-0">
                            
                            

                        </div>
                    </div> 
                

                    <div className="-mb-36 md:-mb-56 relative z-10">
                        <div className="container">
                            <div className="mx-2 lg:mx-10 xl:mx-44">
                                <div className="py-10 md:py-20 mx-auto bg-color-red flex bg-map-red bg-yellow-300 rounded-xl overflow-hidden">
                                    <div className="m-auto">
                                        <div className="px-4 whitespace-normal mt-4 text-white text-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold xl:leading-7">
                                            Be a part of us by donating here
                                        </div>
                                        <div className="flex px-4 mt-3 sm:mt-6 xl:mt-10">
                                            <div className="m-auto flex space-x-2 md:space-x-4">
                                                <a href="https://paystack.com/pay/tadarofoundation" passHref={true} className="btn btn-lg animate btn-white">
                                                    Click to Donate Online
                                                </a>
                                                
                                               {/** <Link to="" className="btn btn-lg text-white animate hover:bg-white hover:text-black">
                                                    Learn more
                                                    <FontAwesomeIcon icon="angle-right" className="ml-2" />
                                                </Link>  */}
                                            </div>
                                           
                                        </div>
                                        <div className="text-center md:text-lg xl:text-lg font-light mt-7 xl:mt-4 text-white">
                                        Send donations directly through our bank details: 
                                        <p>Tadaro Foundation, Zenith Bank Plc - 1221525088 </p>
                                        
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-36 md:h-56 bg-black"></div>

                </div>


                <Footer />
            </>
        );
    }
}

export default Home;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrow from "assets/images/icons/arrow.svg";
import bggirl from "assets/images/bgs/bggirl.png";

class Header extends Component {
    state = {};

    render() {
        return (
            <>
                <div className="bg-white opacity-95 relative">
                    <div className="container z-50 h-14 md:h-20 mx-auto flex">
                        <div className="w-full my-auto flex text-black">

                            <div className="flex-shrink-0 my-auto">
                            <div className="my-4 xl:my-6">
                                    
                                </div>
                                <NavLink to="/" className="text-2xl md:text-3xl  hover:text-juvi-red">
                                    
                                </NavLink>
                                <div className="my-4 xl:my-6">
                                    <img src={bggirl} alt="Arrow" className="object-contain h-14 xl:h-10" />
                                </div>
                            </div>

                         {/**  <div className="flex-grow hidden md:block">
                                <div className="flex h-full justify-end">
                                    <div className="flex my-auto xl:mr-24 space-x-8 lg:space-x-12">
                                        <NavLink to="" className="header-links">
                                            Vision
                                        </NavLink>
                                        <NavLink to="" className="header-links">
                                           Mission
                                        </NavLink>
                                        <NavLink to="" className="header-links">
                                            The Team
                                        </NavLink>
                                        <NavLink to="" className="header-links">
                                            
                                        </NavLink>
                                        <NavLink to="" className="header-links header-links-highlight bg-juvi-red">
                                            Contact Us
                                        </NavLink>
                                    </div>
                                </div>
        </div> */}

                            {/* <Menu>
                                <div className="w-full block md:hidden">
                                    <Menu.Button as="button" className="w-10 h-10 text-white group hover:bg-white flex float-right rounded cursor-pointer">
                                            <FontAwesomeIcon icon="bars" className="text-xl m-auto group-hover:text-black" />
                                    </Menu.Button>
                                </div>
                                <Menu.Items>
                                    <Menu.Item>
                                        <div className="absolute min-h-screen z-40 top-14 inset-x-0 pb-20 bg-black text-white bg-opacity-90">
                                            <div>
                                                <div className="mt-24 space-y-2 text-center">
                                                    <div>
                                                        <NavLink to="" className="header-links-mobile">
                                                            Vision
                                                        </NavLink>
                                                    </div>
                                                    <div>
                                                        <NavLink to="" className="header-links-mobile">
                                                            Mission
                                                        </NavLink>
                                                    </div>
                                                    <div>
                                                        <NavLink to="" className="header-links-mobile">
                                                            The Team
                                                        </NavLink>
                                                    </div>
                                                    <div>
                                                        <NavLink to="" className="header-links-mobile">
                                                            
                                                        </NavLink>
                                                    </div>
                                                    <div>
                                                        <NavLink to="" className="header-links-mobile-highlight">
                                                       Contact Us
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
    </Menu> */}

                        </div>
                    </div>
                
                </div>
            </>
        );
    }
}

export default Header;

import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

// import GeneralLayout from './components/layouts/generalLayout';
import Error404 from './components/misc/error404';
import Home from './components/pages/home';

import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);


function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>

                    <Route exact path="/" component={Home} />
                    <Route exact path="/404" component={Error404} />
                    <Redirect to="/404" />

                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;